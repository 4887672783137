// Variable
@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/variable/font';
@use '../../../../stories/scss/settings/spacing';

.GlobalSearchBlock {
  padding: spacing.$s6 spacing.$s2 0;

  @media screen and (min-width: breakpoint.$lg) {
    padding: spacing.$s8 spacing.$s8 0 0;
    margin-left: 0;
    margin-right: 0;

    &:last-child {
      padding-bottom: spacing.$s8;
    }
  }

  &__title {
    font-family: font.$secondary;
    font-size: font.$size-sm;
    margin-bottom: spacing.$s4;

    @media screen and (min-width: breakpoint.$lg) {
      margin-bottom: spacing.$s6;
    }
  }

  &__empty {
    margin-bottom: spacing.$s6;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      font-size: font.$size-icon;
    }
  }
}
