// Variable
@use '../../../../../scss/variable/breakpoint';

.ComplementaryResults {
  @media screen and (min-width: breakpoint.$lg) {
    flex: 1.5;
  }

  @media screen and (min-width: breakpoint.$xl) {
    flex: 1;
  }
}
