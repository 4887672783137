// Variable
@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/variable/color';
@use '../../../../scss/variable/font';
@use '../../../../stories/scss/settings/spacing';
@use '../../../../scss/variable/shadow';
@use '../../../../scss/variable/z-index';

// Mixin
@use '../../../../scss/mixin/container';

.App {
  top: 0;
  left: 0;
  z-index: 18011;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: var(--theme-color-background);
  overflow: scroll;

  @media screen and (min-width: breakpoint.$lg) {
    position: static;
    overflow: initial;
  }

  &__main {
    @media screen and (min-width: breakpoint.$lg) {
      background-color: var(--theme-color-background);
      position: absolute;
      left: 0;
      right: 0;
      z-index: 18011;
      box-shadow: 0 1px 0 var(--theme-color-base);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    @include container.wrapper;

    @media screen and (max-width: breakpoint.$lg) {
      padding-bottom: 112px;
    }

    @media screen and (min-width: breakpoint.$lg) {
      flex-direction: row;
    }
  }

  &__noDisplayOnDesktop {
    display: block;

    @media screen and (min-width: breakpoint.$lg) {
      display: none;
    }
  }

  &__showMoreButton {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: var(--theme-color-background);
    box-shadow: 0 -1px 0 var(--theme-color-base);
    padding: spacing.$s4;
    z-index: z-index.$low-3;

    &:empty {
      display: none;
    }
  }
}
