@use '../../../../../scss/variable/breakpoint';
@use '../../../../../scss/variable/color';
@use '../../../../../stories/scss/settings/spacing';
@use '../../../../../scss/variable/z-index';

.Input {
  --height: 50px;
  padding: 0 spacing.$s10 0 spacing.$s6;
  height: var(--height);
  background-color: color.$bg-grey;
  border: 1px solid transparent;
  width: 100%;
  border-radius: var(--height);
  font-size: 15px;
  line-height: 1rem;

  &__open {
    padding-left: 48px;
  }

  &:focus {
    background-color: var(--theme-color-background);
  }

  @media screen and (min-width: breakpoint.$lg) {
    padding: 0 calc(var(--height) / 2);
    height: var(--height);
    box-shadow: none;
    background-color: color.$bg-grey;

    &:focus {
      border-color: var(--theme-color-base-semi-dark);
    }
  }
}

.Form {
  position: sticky;
  top: 0;
  z-index: z-index.$low-3;
  display: flex;
  max-height: 50px;
  overflow: hidden;

  &__open {
    padding: spacing.$s4;
    background-color: var(--theme-color-background);
    box-shadow: 0 0 0 1px var(--theme-color-base);
    max-height: 100%;
  }

  @media screen and (min-width: breakpoint.$lg) {
    position: relative;
    top: auto;

    &__open {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
      max-height: 50px;
    }
  }
}

.Button {
  position: absolute;
  background: none;
  border: none;

  &__left-side {
    left: 16px;
    top: 16px;
    padding: 0;
    width: 50px;
    height: 50px;

    @media screen and (min-width: breakpoint.$lg) {
      display: none;
    }
  }

  &__right-side {
    top: 0;
    right: 8px;
    width: 50px;
    height: 50px;
    padding: 0;

    @media screen and (min-width: breakpoint.$lg) {
      top: 0;
      right: 8px;
    }
  }

  &__open {
    right: 24px;
    top: 16px;

    @media screen and (min-width: breakpoint.$lg) {
      top: 0;
      right: 8px;
    }
  }
}
