// Variable
@use '../../../../../scss/variable/breakpoint';
@use '../../../../../stories/scss/settings/spacing';

.Container {
  @media screen and (min-width: breakpoint.$lg) {
    flex: 2.5;
  }

  @media screen and (min-width: breakpoint.$xl) {
    flex: 3;
  }

  &_reverse {
    @media screen and (max-width: breakpoint.$lg) {
      order: -1;
    }
  }
}

.Products {
  border-bottom: 0;
  padding-bottom: 0;

  &:first-child:not(:only-child) {
    border-top: 0;
  }

  @media screen and (min-width: breakpoint.$lg) {
    padding: spacing.$s8 0;
  }

  &__results-count {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
}
